import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { useSearchParams } from "react-router-dom";

function App() {
  const [params] = useSearchParams();
  const auth_code = params.get("auth_code");
  if (auth_code)
    return (
      <h1>
        Login success, auth code:
        <br />
        {auth_code}
      </h1>
    );
  else
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Thoughts Train AI</p>
        </header>
      </div>
    );
}

export default App;
